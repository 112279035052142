import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { environment } from 'src/environments/environment';

const app = initializeApp(environment.firebaseConfig);

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http:HttpClient) { }

  login(email:string,password:string) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  }

  validateAuth(){
    console.log("AuthService | validateAuth | localStorage.getItem('user') " + localStorage.getItem("user"));
    // const user = JSON.parse(localStorage.getItem("user") || '');
    // console.log("AuthService | validateAuth | user " + user);
    if(localStorage.getItem("user") != null){
      const user = JSON.parse(localStorage.getItem("user") || '');
      const now = new Date().getTime();
      const tokenExpiredTime = user.stsTokenManager.expirationTime;
      if(now > tokenExpiredTime){
        localStorage.removeItem("user");
        return false
      }
      return true;
    }
    return false;
  }

  getToken():string{
    const user = JSON.parse(localStorage.getItem("user") || '');
    return user.stsTokenManager.accessToken;
  }

  getUserDisplayName():string{
    const user = JSON.parse(localStorage.getItem("user") || '');
    return user.displayName;
  }

  getUserPhoneNumber():string{
    const user = JSON.parse(localStorage.getItem("user") || '');
    if(user.phoneNumber != null)
      return user.phoneNumber;
    return "Tidak Ditemukan"
  }

  logout() {
    localStorage.removeItem("user");
    const auth = getAuth();
    return signOut(auth);
  }
}
